<template>
  <v-container>
    <page-title title="編輯Line@訊息" />
    <eagle-form :form-key="formKey" >
    </eagle-form>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './providerLineMessageEdit'

export default {
  mixins: [formMixin],
  components: {

  },
  
  data: () => ({
    formKey: 'provider-line-message-edit-form',
    meta: {},
  }),

  computed: {
    isMainManager() {
      return this.$store.getters['member/isProviderMainManager']
    },
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    memberId() {
      return this.$store.getters[`token/memberId`]
    },
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    afterSaveSuccessCallback(result) {

    },
    async readApi(target) {
      const res =  await this.$api.collection.providerApi.read(this.providerId)

      return res
    },
    async updateApi(target, formData) {

      await this.$api.collection.providerApi.MessageTextUpdate(this.providerId, formData)
      const res =  await this.$api.collection.providerApi.read(this.providerId)
      this.$store.dispatch('base/setLayoutProvider', res)

    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
